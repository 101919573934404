import {jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";
import {useState} from "react";


import CheckBox from "./CheckBox";
import { TRANSFORMS} from "./Constants";
import OptionsBox from "./OptionsBox";
import SimpleSelect from "./SimpleSelect";
import TextInput from "./TextInput";






function addTransform(transforms, transform) {
  if (transforms.includes(transform)) {
    return transforms;
  }
  const newTransforms = [...transforms, transform];
  // Keep the order canonical for easy comparison.
  newTransforms.sort((t1, t2) => TRANSFORMS.indexOf(t1) - TRANSFORMS.indexOf(t2));
  return newTransforms;
}

function removeTransform(transforms, transform) {
  return transforms.filter((t) => t !== transform);
}














function BooleanOption({optionName, options, onUpdateOptions}) {
  return (
    _jsx(CheckBox, {
      label: _jsx('span', { className: css(styles.optionName), children: optionName}),
      checked: options[optionName],
      onChange: (checked) => {
        onUpdateOptions({...options, [optionName]: checked});
      },}
    )
  );
}








function StringOption({
  optionName,
  options,
  onUpdateOptions,
  inputWidth,
}) {
  return (
    _jsxs('div', { className: css(styles.option), children: [
      /* Include a hidden checkbox so the option name is vertically aligned
          with the boolean options. */
      _jsx('input', { type: "checkbox", className: css(styles.hiddenCheckbox),} )
      , _jsxs('span', { className: css(styles.optionName), children: [
        optionName, ":", " "
        , _jsx(TextInput, {
          value: options[optionName],
          onChange: (value) => {
            onUpdateOptions({...options, [optionName]: value});
          },
          width: inputWidth,}
        )
      ]})
    ]})
  );
}






function JSXRuntimeOption({options, onUpdateOptions}) {
  return (
    _jsxs('div', { className: css(styles.option), children: [
      /* Include a hidden checkbox so the option name is vertically aligned
          with the boolean options. */
      _jsx('input', { type: "checkbox", className: css(styles.hiddenCheckbox),} )
      , _jsxs('span', { className: css(styles.optionName), children: ["jsxRuntime:"
        , " "
        , _jsx(SimpleSelect, {
          options: ["classic", "automatic", "preserve"],
          value: options.jsxRuntime,
          onChange: (value) => {
            onUpdateOptions({...options, jsxRuntime: value});
          },}
        )
      ]})
    ]})
  );
}

export default function SucraseOptionsBox({
  options,
  onUpdateOptions,
}) {
  const [showSecondaryOptions, setShowSecondaryOptions] = useState(false);
  return (
    _jsx(OptionsBox, { children: 
      _jsxs('div', { className: css(styles.container), children: [
        _jsxs('div', { className: css(styles.mainOptions), children: [
          _jsx('span', { className: css(styles.title), children: "Options"})
          , TRANSFORMS.filter(
            (t) => showSecondaryOptions || ["jsx", "typescript", "flow", "imports"].includes(t),
          ).map((transformName) => (
            _jsx(CheckBox, {

              label: transformName,
              checked: options.transforms.includes(transformName),
              onChange: (checked) => {
                let newTransforms = options.transforms;
                if (checked) {
                  // TypeScript and Flow are mutually exclusive, so enabling one disables the other.
                  if (transformName === "typescript") {
                    newTransforms = removeTransform(newTransforms, "flow");
                  } else if (transformName === "flow") {
                    newTransforms = removeTransform(newTransforms, "typescript");
                  }
                  newTransforms = addTransform(newTransforms, transformName);
                } else {
                  newTransforms = removeTransform(newTransforms, transformName);
                }
                onUpdateOptions({...options, transforms: newTransforms});
              },}, transformName
            )
          ))
          , _jsx('a', {
            href: "#more",
            className: css(styles.link),
            onClick: (e) => {
              setShowSecondaryOptions(!showSecondaryOptions);
              e.preventDefault();
            },
 children: 
            showSecondaryOptions ? "Collapse" : "More..."
          })
        ]})
        , showSecondaryOptions && (
          _jsxs('div', { className: css(styles.secondaryOptions), children: [
            _jsxs('div', { className: css(styles.secondaryOptionColumn), children: [
              _jsx(BooleanOption, {
                optionName: "disableESTransforms",
                options: options,
                onUpdateOptions: onUpdateOptions,}
              )
              , options.transforms.includes("jsx") && (
                _jsxs(_Fragment, { children: [
                  _jsx(JSXRuntimeOption, { options: options, onUpdateOptions: onUpdateOptions,} )
                  , options.jsxRuntime !== "preserve" && (
                    _jsx(BooleanOption, {
                      optionName: "production",
                      options: options,
                      onUpdateOptions: onUpdateOptions,}
                    )
                  )
                  , options.jsxRuntime === "classic" && (
                    _jsxs(_Fragment, { children: [
                      _jsx(StringOption, {
                        optionName: "jsxPragma",
                        options: options,
                        onUpdateOptions: onUpdateOptions,
                        inputWidth: 182,}
                      )
                      , _jsx(StringOption, {
                        optionName: "jsxFragmentPragma",
                        options: options,
                        onUpdateOptions: onUpdateOptions,
                        inputWidth: 120,}
                      )
                    ]})
                  )

                  , options.jsxRuntime === "automatic" && (
                    _jsx(StringOption, {
                      optionName: "jsxImportSource",
                      options: options,
                      onUpdateOptions: onUpdateOptions,
                      inputWidth: 116,}
                    )
                  )
                ]})
              )
            ]})
            , _jsxs('div', { className: css(styles.secondaryOptionColumn), children: [
              (options.transforms.includes("typescript") ||
                options.transforms.includes("flow")) && (
                _jsx(BooleanOption, {
                  optionName: "keepUnusedImports",
                  options: options,
                  onUpdateOptions: onUpdateOptions,}
                )
              )
              , options.transforms.includes("imports") && (
                _jsxs(_Fragment, { children: [
                  _jsx(BooleanOption, {
                    optionName: "preserveDynamicImport",
                    options: options,
                    onUpdateOptions: onUpdateOptions,}
                  )
                  , _jsx(BooleanOption, {
                    optionName: "enableLegacyTypeScriptModuleInterop",
                    options: options,
                    onUpdateOptions: onUpdateOptions,}
                  )
                  , _jsx(BooleanOption, {
                    optionName: "enableLegacyBabel5ModuleInterop",
                    options: options,
                    onUpdateOptions: onUpdateOptions,}
                  )
                ]})
              )
              , !options.transforms.includes("imports") &&
                options.transforms.includes("typescript") && (
                  _jsx(BooleanOption, {
                    optionName: "injectCreateRequireForImportRequire",
                    options: options,
                    onUpdateOptions: onUpdateOptions,}
                  )
                )
            ]})
          ]})
        )
      ]})
    })
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.2em",
    padding: 6,
  },
  mainOptions: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  link: {
    color: "#CCCCCC",
    marginLeft: 6,
    marginRight: 6,
  },
  secondaryOptions: {
    display: "flex",
    justifyContent: "space-around",
  },
  secondaryOptionColumn: {
    display: "flex",
    flexDirection: "column",
  },
  option: {
    marginLeft: 6,
    marginRight: 6,
    display: "flex",
  },
  hiddenCheckbox: {
    visibility: "hidden",
  },
  optionName: {
    fontFamily: "monospace",
  },
});
