import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {css, StyleSheet} from "aphrodite";
import {useEffect, useRef, useState} from "react";
import {hot} from "react-hot-loader/root";

import CompareOptionsBox from "./CompareOptionsBox";
import {

  DEFAULT_DEBUG_OPTIONS,
  DEFAULT_COMPARE_OPTIONS,
  DEFAULT_OPTIONS,

  INITIAL_CODE,
} from "./Constants";
import DebugOptionsBox from "./DebugOptionsBox";
import EditorWrapper from "./EditorWrapper";
import SucraseOptionsBox from "./SucraseOptionsBox";
import { loadHashState, saveHashState} from "./URLHashState";
import * as WorkerClient from "./WorkerClient";


function App() {
  const cachedHashState = useRef("NOT_LOADED");
  function hashState() {
    if (cachedHashState.current === "NOT_LOADED") {
      cachedHashState.current = loadHashState();
    }
    return cachedHashState.current;
  }

  const [code, setCode] = useState(_nullishCoalesce(_optionalChain([hashState, 'call', _ => _(), 'optionalAccess', _2 => _2.code]), () => ( INITIAL_CODE)));
  const [sucraseOptions, setSucraseOptions] = useState(
    _nullishCoalesce(_optionalChain([hashState, 'call', _3 => _3(), 'optionalAccess', _4 => _4.sucraseOptions]), () => ( DEFAULT_OPTIONS)),
  );
  const [compareOptions, setCompareOptions] = useState(
    _nullishCoalesce(_optionalChain([hashState, 'call', _5 => _5(), 'optionalAccess', _6 => _6.compareOptions]), () => ( DEFAULT_COMPARE_OPTIONS)),
  );
  const [debugOptions, setDebugOptions] = useState(
    _nullishCoalesce(_optionalChain([hashState, 'call', _7 => _7(), 'optionalAccess', _8 => _8.debugOptions]), () => ( DEFAULT_DEBUG_OPTIONS)),
  );
  const [sucraseCode, setSucraseCode] = useState("");
  const [sucraseTimeMs, setSucraseTimeMs] = useState(null);
  const [babelCode, setBabelCode] = useState("");
  const [babelTimeMs, setBabelTimeMs] = useState(null);
  const [typeScriptCode, setTypeScriptCode] = useState("");
  const [typeScriptTimeMs, setTypeScriptTimeMs] = useState(null);
  const [tokensStr, setTokensStr] = useState("");
  const [sourceMapStr, setSourceMapStr] = useState("");
  const [babelLoaded, setBabelLoaded] = useState(false);
  const [typeScriptLoaded, setTypeScriptLoaded] = useState(false);

  useEffect(() => {
    WorkerClient.updateHandlers({
      updateState: (stateUpdate) => {
        const setters

 = {
          sucraseCode: setSucraseCode,
          babelCode: setBabelCode,
          typeScriptCode: setTypeScriptCode,
          tokensStr: setTokensStr,
          sourceMapStr: setSourceMapStr,
          sucraseTimeMs: setSucraseTimeMs,
          babelTimeMs: setBabelTimeMs,
          typeScriptTimeMs: setTypeScriptTimeMs,
          babelLoaded: setBabelLoaded,
          typeScriptLoaded: setTypeScriptLoaded,
        };
        // The above mapping ensures we list all properties in StateUpdate with the right types.
        // Use escape hatches for actually setting the properties.
        for (const [key, setter] of Object.entries(setters)) {
          if (stateUpdate[key ] !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setter )(stateUpdate[key ]);
          }
        }
      },
      handleCompressedCode: (compressedCode) => {
        saveHashState({
          code,
          compressedCode,
          sucraseOptions,
          compareOptions,
          debugOptions,
        });
      },
    });
  }, [code, sucraseOptions, compareOptions, debugOptions]);

  // On any change to code, config, or loading state, kick off a worker task to re-calculate.
  useEffect(() => {
    setSucraseTimeMs("LOADING");
    setBabelTimeMs("LOADING");
    setTypeScriptTimeMs("LOADING");
    WorkerClient.updateConfig({code, sucraseOptions, compareOptions, debugOptions});
  }, [code, sucraseOptions, compareOptions, debugOptions, babelLoaded, typeScriptLoaded]);

  return (
    _jsxs('div', { className: css(styles.app), children: [
      _jsx('span', { className: css(styles.title), children: "Sucrase"})
      , _jsxs('span', { className: css(styles.subtitle), children: [
        _jsx('span', { children: "Super-fast Babel alternative"  })
        , " | "
        , _jsx('a', { className: css(styles.link), href: "https://github.com/alangpierce/sucrase", children: "GitHub"

        })
      ]})
      , _jsxs('div', { className: css(styles.options), children: [
        _jsx(SucraseOptionsBox, {
          options: sucraseOptions,
          onUpdateOptions: (newSucraseOptions) => {
            setSucraseOptions(newSucraseOptions);
          },}
        )
        , _jsx(CompareOptionsBox, {
          compareOptions: compareOptions,
          onUpdateCompareOptions: (newCompareOptions) => {
            setCompareOptions(newCompareOptions);
          },}
        )
        , _jsx(DebugOptionsBox, {
          debugOptions: debugOptions,
          onUpdateDebugOptions: (newDebugOptions) => {
            setDebugOptions(newDebugOptions);
          },}
        )
      ]})

      , _jsxs('div', { className: css(styles.editors), children: [
        _jsx(EditorWrapper, { label: "Your code" , code: code, onChange: setCode, babelLoaded: babelLoaded,} )
        , _jsx(EditorWrapper, {
          label: "Transformed with Sucrase"  ,
          code: sucraseCode,
          timeMs: sucraseTimeMs,
          isReadOnly: true,
          babelLoaded: babelLoaded,}
        )
        , compareOptions.compareWithBabel && (
          _jsx(EditorWrapper, {
            label: "Transformed with Babel"  ,
            code: babelCode,
            timeMs: babelTimeMs,
            isReadOnly: true,
            babelLoaded: babelLoaded,}
          )
        )
        , compareOptions.compareWithTypeScript && (
          _jsx(EditorWrapper, {
            label: "Transformed with TypeScript"  ,
            code: typeScriptCode,
            timeMs: typeScriptTimeMs,
            isReadOnly: true,
            babelLoaded: babelLoaded,}
          )
        )
        , debugOptions.showTokens && (
          _jsx(EditorWrapper, {
            label: "Tokens",
            code: tokensStr,
            isReadOnly: true,
            isPlaintext: true,
            options: {
              lineNumbers: (n) => (n > 1 ? String(n - 2) : ""),
            },
            babelLoaded: babelLoaded,}
          )
        )
        , debugOptions.showSourceMap && (
          _jsx(EditorWrapper, {
            label: "Source Map" ,
            code: sourceMapStr,
            isReadOnly: true,
            isPlaintext: true,
            babelLoaded: babelLoaded,}
          )
        )
      ]})
      , _jsxs('span', { className: css(styles.footer), children: [
        _jsx('a', { className: css(styles.link), href: "https://www.npmjs.com/package/sucrase", children: "sucrase"

        }), " "
        , process.env.SUCRASE_VERSION
      ]})
    ]})
  );
}

export default hot(App);

const styles = StyleSheet.create({
  app: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#222",
  },
  title: {
    fontSize: "2em",
    color: "white",
    fontWeight: "bold",
    padding: 8,
  },
  subtitle: {
    fontSize: "1.2em",
    color: "white",
  },
  link: {
    color: "#CCCCCC",
  },
  options: {
    textAlign: "center",
    color: "white",
  },
  footer: {
    fontSize: "large",
    color: "white",
    marginBottom: 8,
  },
  editors: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
});
