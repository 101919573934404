/**
 * Given an exactly-typed object (where T specifies all keys), enumerate entries.
 */
export function entriesExact(obj) {
  return Object.entries(obj) ;
}

/**
 * Given an exactly-typed object (where T specifies all keys), check for key existence.
 */
export function hasKeyExact(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
