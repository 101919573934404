import {jsx as _jsx} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";







/**
 * Select component where items are identified directly by their value.
 */
export default function SimpleSelect({
  options,
  value,
  onChange,
}) {
  return (
    _jsx('select', {
      className: css(styles.select),
      value: value,
      onChange: (e) => {
        onChange(e.target.value );
      },
 children: 
      options.map((option) => (
        _jsx('option', { value: option, children: 
          option
        }, option)
      ))
    })
  );
}

const styles = StyleSheet.create({
  select: {
    backgroundColor: "#222222",
    color: "white",
    border: 0,
    fontFamily: "monospace",
  },
});
