import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";
import {useState} from "react";

import CheckBox from "./CheckBox";

import OptionsBox from "./OptionsBox";






export default function DebugOptionsBox({
  debugOptions,
  onUpdateDebugOptions,
}) {
  const [enabled, setEnabled] = useState(false);
  if (!enabled) {
    return (
      _jsx('a', {
        href: "#debug",
        className: css(styles.link),
        onClick: (e) => {
          setEnabled(true);
          e.preventDefault();
        },
 children: "Debug..."

      })
    );
  }
  return (
    _jsx(OptionsBox, { children: 
      _jsxs('div', { className: css(styles.optionBox), children: [
        _jsx('span', { className: css(styles.title), children: "Debug"})
        , _jsx(CheckBox, {
          label: "Tokens",
          checked: debugOptions.showTokens,
          onChange: (checked) => {
            onUpdateDebugOptions({...debugOptions, showTokens: checked});
          },}
        )
        , _jsx(CheckBox, {
          label: "Source Map" ,
          checked: debugOptions.showSourceMap,
          onChange: (checked) => {
            onUpdateDebugOptions({...debugOptions, showSourceMap: checked});
          },}
        )
      ]})
    })
  );
}

const styles = StyleSheet.create({
  optionBox: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  link: {
    color: "#CCCCCC",
    marginLeft: 6,
    marginRight: 6,
  },
  title: {
    fontSize: "1.2em",
    padding: 6,
  },
});
