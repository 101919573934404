import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";








export default function CheckBox({label, checked, onChange}) {
  return (
    _jsxs('label', { className: css(styles.label), children: [
      _jsx('input', {
        type: "checkbox",
        className: css(styles.checkbox),
        checked: checked,
        onChange: (e) => {
          onChange(e.target.checked);
        },}
      )
      , label
    ]})
  );
}

const styles = StyleSheet.create({
  label: {
    display: "flex",
    alignItems: "center",
    marginLeft: 6,
    marginRight: 6,
  },
  checkbox: {
    marginRight: 4,
  },
});
