import {jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import {useCallback, useEffect, useRef} from "react";














export default function Editor({
  MonacoEditor,
  code,
  onChange,
  isReadOnly,
  isPlaintext,
  options,
  width,
  height,
}) {
  const editorRef = useRef(null);

  const editorDidMount = useCallback((monacoEditor, monaco) => {
    editorRef.current = monacoEditor;
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true,
      noSuggestionDiagnostics: true,
    });
    monacoEditor.layout();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      _optionalChain([editorRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.layout, 'call', _3 => _3()]);
    }, 0);
  }, []);

  return (
    _jsx(MonacoEditor, {
      editorDidMount: editorDidMount,
      width: width,
      height: height,
      language: isPlaintext ? undefined : "typescript",
      theme: "vs-dark",
      value: code,
      onChange: onChange,
      options: {
        minimap: {enabled: false},
        readOnly: isReadOnly,
        ...options,
      },}
    )
  );
}
