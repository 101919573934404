import {jsx as _jsx} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";






export default function OptionsBox({children}) {
  return _jsx('div', { className: css(styles.optionsBox), children: children});
}

const styles = StyleSheet.create({
  optionsBox: {
    display: "inline-flex",
    border: "1px solid white",
    borderRadius: 6,
    margin: 10,
    padding: 4,
    backgroundColor: "#333333",
  },
});
