import {jsxs as _jsxs, jsx as _jsx} from "react/jsx-runtime"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {css, StyleSheet} from "aphrodite";

import {useEffect, useRef, useState} from "react";

import AutoSizer from "react-virtualized-auto-sizer";

import Editor from "./Editor";
import FallbackEditor from "./FallbackEditor";












export default function EditorWrapper({
  label,
  code,
  onChange,
  isReadOnly,
  isPlaintext,
  options,
  timeMs,
  babelLoaded,
}) {
  const innerEditor = useRef(null);

  const [monacoEditorModule, setMonacoEditorModule] = useState(
    null,
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (babelLoaded && !monacoEditorModule) {
        setMonacoEditorModule(await import("react-monaco-editor"));
      }
    })();
  }, [babelLoaded, monacoEditorModule]);

  function formatTime() {
    if (timeMs == null) {
      return "";
    } else if (timeMs === "LOADING") {
      return " (...)";
    } else {
      return ` (${Math.round(timeMs * 100) / 100}ms)`;
    }
  }

  function invalidate() {
    _optionalChain([innerEditor, 'access', _ => _.current, 'optionalAccess', _2 => _2.layout, 'call', _3 => _3()]);
  }

  return (
    _jsxs('div', { className: css(styles.editor), children: [
      _jsxs('span', { className: css(styles.label), children: [
        label
        , formatTime()
      ]})
      , _jsx('span', { className: css(styles.container), children: 
        _jsx(AutoSizer, { onResize: invalidate, defaultWidth: 300, defaultHeight: 300, children: 
          
            // TODO: The explicit type params can be removed once we're on TS 5.1
            //  https://github.com/bvaughn/react-virtualized-auto-sizer/issues/63
            ({width, height}) =>
              monacoEditorModule ? (
                _jsx(Editor, {
                  onMount: (editor) => {
                    innerEditor.current = editor;
                  },
                  MonacoEditor: monacoEditorModule.default,
                  width: width,
                  height: height - 30,
                  code: code,
                  onChange: onChange,
                  isPlaintext: isPlaintext,
                  isReadOnly: isReadOnly,
                  options: options,}
                )
              ) : (
                _jsx(FallbackEditor, {
                  width: width,
                  height: height - 30,
                  code: code,
                  onChange: onChange,
                  isReadOnly: isReadOnly,}
                )
              )
          
        })
      })
    ]})
  );
}

const styles = StyleSheet.create({
  editor: {
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    height: "100%",
    flex: 1,
    // When adding a third editor, we need the container size to shrink so that
    // the Monaco layout code will adjust to the container size.
    overflowX: "hidden",
    margin: 8,
  },
  label: {
    color: "white",
    lineHeight: "30px",
    padding: 8,
  },
  container: {
    height: "100%",
  },
});
