import {jsx as _jsx} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";







export default function TextInput({value, onChange, width}) {
  return (
    _jsx('input', {
      type: "text",
      className: css(styles.input),
      style: {width},
      value: value,
      onChange: (e) => {
        onChange(e.target.value);
      },}
    )
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "#222222",
    color: "white",
    border: 0,
    fontFamily: "monospace",
  },
});
