import {jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";import {css, StyleSheet} from "aphrodite";

import CheckBox from "./CheckBox";

import OptionsBox from "./OptionsBox";






export default function CompareOptionsBox({
  compareOptions,
  onUpdateCompareOptions,
}) {
  return (
    _jsx(OptionsBox, { children: 
      _jsxs('div', { className: css(styles.optionBox), children: [
        _jsx('span', { className: css(styles.title), children: "Compare"})
        , _jsx(CheckBox, {
          label: "Babel",
          checked: compareOptions.compareWithBabel,
          onChange: (checked) => {
            onUpdateCompareOptions({...compareOptions, compareWithBabel: checked});
          },}
        )
        , _jsx(CheckBox, {
          label: "TypeScript",
          checked: compareOptions.compareWithTypeScript,
          onChange: (checked) => {
            onUpdateCompareOptions({...compareOptions, compareWithTypeScript: checked});
          },}
        )
      ]})
    })
  );
}

const styles = StyleSheet.create({
  optionBox: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  title: {
    fontSize: "1.2em",
    padding: 6,
  },
});
